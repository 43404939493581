<template>
	<!-- <div class="mcol-xs-6 mcol-sm-4 mcol-md-3"> -->
	<!-- :on-preview="handlePreview" -->
	<!-- :on-remove="handleRemove" -->
	<!-- :before-remove="beforeRemove" -->
	<!-- :on-exceed="handleExceed" -->
	<el-upload
		ref="uploadContainer"
		:on-change="onSelectFile"
		action="#"
		class="upload-container"
		:auto-upload="false"
		:multiple="multiple"
		drag
		accept="image/jpg, image/jpeg, image/png"
		list-type="picture"
		:file-list="fileList"
	>
		<i class="el-icon-upload"></i>
		<div class="el-upload__text">
			Перетащите изображение сюда или <em>кликните для загрузки</em>
		</div>
		<div slot="tip" class="el-upload__tip">jpg/png не более 5000kb</div>

		<div slot="file" slot-scope="{ file }">
			<div :class="['img-container', { 'without-footer': replaceSelectedFile }]">
				<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
			</div>

			<div class="el-upload-list__item-name">{{ file.name }}</div>

			<div v-if="!replaceSelectedFile" class="footer-block">
				<!-- <div class="customRadioContainer">
					<input
						type="radio"
						name="is_main_group"
						class=""
						:checked="file.is_main ? `checked` : ''"
						@click="handleMainImgChange(file)"
						:id="`is_main_radio-${file.uid}`"
					/>
					<label :for="`is_main_radio-${file.uid}`"
						><span><b>Главное</b></span></label
					>
				</div> -->

				<div class="remove-button-container">
					<el-button
						class="action-button"
						size="mini"
						type="danger"
						icon="icomoon icon-cross"
						@click="handleDeleteFile({ uid: file.uid, id: file.id })"
					/>
				</div>
			</div>
		</div>
	</el-upload>
</template>

<script>
export default {
	components: {
		ElUpload: () =>
			import(/* webpackChunkName: "ElUpload" */ 'element-ui/lib/upload')
	},
	props: {
		options: {
			type: Object,
			default: () => ({})
		},
		pictures: {
			type: Array,
			default: () => []
		},
		picture: String,
		replaceSelectedFile: Boolean,
		multiple: Boolean,
		mainFile: Boolean,
		uid: String
	},

	data() {
		return {
			fileList: [],
			pictures_id: []
		};
	},

	created() {
		this.fileList = this.setupImgList(this.picture || this.pictures);
		this.$emit('onInit', {
			uid: this.uid,
			prepareFilesData: this.prepareFilesData
		});
	},

	methods: {
		/* handleMainImgChange(file) {
			const { uploadContainer } = this.$refs;

			for (let fileListItem of uploadContainer.uploadFiles) {
				if (fileListItem.uid == file.uid) {
					fileListItem.is_main = 1;
				} else {
					fileListItem.is_main = 0;
				}
			}
		}, */

		onSelectFile(file) {
			if (this.replaceSelectedFile) {
				const { uploadContainer } = this.$refs;
				if (uploadContainer.uploadFiles.length > 1) {
					uploadContainer.uploadFiles = [];
					setTimeout(() => {
						uploadContainer.uploadFiles = [{ ...file }];
					}, 450);
				}
			}
		},

		handleDeleteFile({ uid, id }) {
			const { uploadContainer } = this.$refs;
			if (uid) {
				uploadContainer.uploadFiles = uploadContainer.uploadFiles.filter(
					f => f.uid != uid
				);
			}
			if (id) {
				this.pictures_id.push(id);
			}
		},

		prepareFilesData() {
			const { uploadContainer } = this.$refs;
			let result = {};

			if (this.replaceSelectedFile) {
				const propName = this.mainFile ? 'mainFile' : 'file';
				result[propName] = null;
				if (uploadContainer.uploadFiles.length) {
					const { raw } = uploadContainer.uploadFiles[0];
					if (raw) {
						result[propName] = raw;
					}
				}
			} else {
				result.pictures_id = this.pictures_id;
				result.files = [];

				for (let file of uploadContainer.uploadFiles) {
					if (file.raw) {
						result.files.push(file.raw);
					}
				}
			}
			return result;
		},

		setupImgList(pictures) {
			let list = [];

			if (pictures instanceof Array) {
				for (let picture of pictures) {
					// console.log(file)
					const item = {
						id: picture.id,
						uid: picture.id,
						url: picture.picture_file_name,
						is_main: picture.is_main,
						display_order: picture.display_order
					};
					// console.log(item)
					list.push(item);
				}
			} else if (pictures) {
				const item = {
					url: pictures
				};
				list.push(item);
			}

			return list;
		}
	}
};
</script>
